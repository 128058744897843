.art-filters {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 12px;
}

.art-filters .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.art-filters .header h3 {
  color: #031A32;
  font-size: 14px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}


.art-filters .header img {
  width: 13px;
  height: 13px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.label {
  color: #E3C352;
  font-size: 14px;
  /* font-family: Avenir Next; */
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 8px;
}

.filter .to {
  margin-bottom: 10px;
  color: var(--light-theme-type-medium-grey, #78909C);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Avenir Next;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 114.286% */
}

.price-filter .filter {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-end;
}

.price-filter .filter .filter-item {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.price-filter .filter .filter-item .label {
  color: #78909C;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.art-filters .filter-item .filter-input {
  display: flex;
}

.art-filters .filter-item .filter-input .input-box {
  width: 50px;
  height: 40px;
  border-radius: 4px 0 0 4px;
  border: 0.5px solid var(--light-theme-card-border-color, #E3E9EF);
  background: #FFF;

  display: flex;
  align-items: center;
  justify-content: center;
}

.art-filters .filter-item .filter-input .input-box input {
  width: 80%;
  padding: 5px;
  display: flex;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.art-filters .filter-item .filter-input .currency-box {
  width: 50px;
  height: 40px;
  border-radius: 0 4px 4px 0;
  border: 0.5px solid var(--light-theme-card-border-color, #E3E9EF);

  background: green;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #78909C;
  font-size: 14px;
  font-weight: 500;
}

.art-filters .filter-item .filter-input .currency-box input {
  width: 80%;
  padding: 5px;
  display: flex;
  background: #fff;
}


/* ***************************************
*********** SEARCH FILTER *************
*************************************** */

.search {
  width: 100%;
  /* display: inline-flex; */
  display: flex;
  height: 40px;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid rgba(92, 92, 92, 0.40);
  align-items: center;
  padding: 0 15px 0 15px;
}

.search input {
  width: 90%;
}

.search-icon {
  width: 16px;
  height: 16px;
}

.suggestions {
  margin-top: 7px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 300px;
  overflow-y: auto;
}

.suggestions .more {
  margin-top: 8px;
  color: var(--black, #000);
  font-size: 16px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 700;
  line-height: 130.8%;
  cursor: pointer;
}

.suggestion {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.suggestion .left img {
  width: 20px;
  aspect-ratio: 1;
  background: #FFF;
  margin-right: 8px;
}

.suggestion .left {
  display: flex;
  align-items: center;
  cursor: pointer;
}


.suggestion .left .uncheck-label {
  color: #5C5C5C;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  text-transform: capitalize;
}

.suggestion .left .check-label {
  color: #4D4D4D;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-transform: capitalize;
}

.count-uncheck {
  color: #858585;
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Avenir Next;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 184.615% */
}

.count-check {
  color: var(--Black, #000);
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Avenir Next;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}








.vertical-line {
  height: 18px;
  background: #5C5C5C;
  width: 1px;
}

.buttons {
  width: 100%;
  display: flex;
  gap: 10px;
}

.buttons .reset,
.buttons .apply {
  display: flex;
  width: 45%;
  padding: 6px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--secondary, #5C5C5C);
  background: #FFF;
  font-weight: 700;
}

.buttons .apply {
  border-radius: 4px;
  background: #4C4C4C;
  color: #FFF;

}