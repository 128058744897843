.wrap {
  width: 40vw;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap-expanded {
  /* margin-top: -20px; */
  width: 95vw;
  display: flex;
  justify-content: center;
  /* position: fixed; */
  height: 40px;
  background: #fff;
  z-index: 999;
}

.search-bar {
  width: 20px;
  height: 20px;
}

.search .searchButton img {
  width: 100%;
  aspect-ratio: 1;
  height: 16px;
  width: 16px;
}

.search-small,
.search-small-expanded {
  width: 40px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  height: 40px;

  border: 1px solid rgba(92, 92, 92, 0.4);
  border-radius: 16px;
  align-items: center;
  transition: 0.5s all;
  overflow: hidden;
}

.search-small {
  position: absolute;
  left: 12rem;
  justify-content: center;
}
.verticalLine {
  height: 18px;
  background: #e6e6e6;
  width: 1px;
}
.search-small input,
.search-small .verticalLine {
  display: none;
}

.search {
  position: relative;
  display: flex;
  justify-content: flex-start;
  height: 40px;
  border: 1px solid rgba(92, 92, 92, 0.4);
  border-radius: 16px;
  align-items: center;
  transition: 0.5s all;
  overflow: hidden;
  width: 100%;
}

.search button,
.search-small button,
.search-small-expanded button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-small-expanded {
  width: 90%;
}

.searchTerm {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #5c5c5c;
  height: 20px;
  width: 85%;
}

.searchTerm::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #5c5c5c;
  height: 20px;
}

.searchButton {
  background: none;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  gap: 8px;
  margin-left: 12px;
  margin-right: 8px;
}

.searchButton-small {
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  gap: 8px;
  margin-left: 9px;
}

.search .searchTerm {
  height: 35px;
  width: 70%;
}

@media screen and (max-width: 700px) {
  .wrap {
    margin-left: 64px;
  }
}
