@font-face {
    font-family: 'Avenir Next';
    src: url('../assets//fonts/AvenirNextLTPro-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  
@font-face {
    font-family: 'Avenir Next';
    src: url('../assets//fonts/AvenirNextLTPro-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  
  /* Define additional font weights and styles if needed */
  