.container {
    position: relative;
    width: 100%;
    min-height: 1.5em;
    border-bottom: .05em solid #D0DAE4;
    display: flex;
    align-items: center;
    gap: .5em;
    padding: .5em;
    outline: none;
    height: 25px;
}

.container:focus {
    border-color: var(--primary);
}

.value {
    flex-grow: 1;
    display: flex;
    gap: .5em;
    flex-wrap: wrap;
}

.clear-btn {
    background: none;
    color: #777;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    font-size: 1.25em;
}

.clear-btn:focus,
.clear-btn:hover {
    color: #333;
}



.caret {
    height: 24px;
    width: 24px;
    background: url("./../assets/icons/caret-icon.svg") no-repeat;
}

.options {
    position: absolute;
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;
    max-height: 150px;
    overflow-y: auto;
    border: .05em solid #777;
    border-radius: .25em;
    width: 100%;
    width: 100%;
    left: 0;
    top: calc(100% + .25em);
    background-color: white;
    z-index: 100;
}

.placeholder {
    color: #B5B5B5;
    font-size: 16px;
    font-weight: 400;
}

.options.show {
    display: block;
}

.option {
    padding: .25em .5em;
    cursor: pointer;
}

.option.selected {
    background-color: rgb(248, 240, 240);
}

.option.highlighted {
    background-color: #4da361;
    color: white
}

.option-badge {
    display: flex;
    align-items: center;
    gap: .25em;
    cursor: pointer;
    background: none;
    outline: none;
    color: var(--light-theme-main-typography, #031A32);
    font-size: 16px;
    font-weight: 500;
}

.option-badge:focus {
    background-color: hsl(0, 7%, 94%);
    border-color: hsl(0, 100%, 50%);
}

.option-badge:hover>.remove-btn,
.option-badge:focus>.remove-btn {
    color: hsl(0, 100%, 50%);
}

.option-badge>.remove-btn {
    font-size: 1.25em;
    color: #777;
}

.more {
    color: var(--primary);
    font-size: 16px;
    font-weight: 600;
}

@media screen and (max-width:850px) {

    .more,
    .option-badge {
        font-size: 14px;
    }


}