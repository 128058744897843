.chat-window {
  height: 500px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 6px;
  border: none;
}

.modal-content {
  border: none;
  border-radius: 12px 12px 12px 12px;
}

.msg-sender {
  margin-right: 15px;
}

.msg-reciever {
  margin-left: 15px;
}

.modalHeader {
  height: 48px;
  width: 100%;
  display: flex;
  background: #407742;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px 6px 0 0;
  color: #d4fca9;
  padding: 0 20px;
}

.modalHeader:first-child {
  font-size: 18px;
  font-weight: 500;
  font-style: Roboto;
}

.chat-section {
  height: 75%;
  width: 100%;
  /* background-color: #82CA9C; */
  /* display: flex;
  flex-direction: column;
  justify-content: flex-end; */
  overflow-y: auto;
  /* gap: 12px; */
}

.chat-item-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-self: center;
}

.chat-item-right p {
  background: #82ca9c;
  border-radius: 18px 18px 4px 18px;
  width: fit-content;
  font-size: 13px;
  font-weight: 400;
  color: white;
  padding: 8px 15px;
  max-width: 50%;
}

.chat-item-left p {
  background: rgba(0, 0, 0, 0.06);
  border-radius: 18px 18px 18px 4px;
  width: fit-content;
  font-size: 13px;
  font-weight: 400;
  color: black;
  padding: 8px 15px;
  max-width: 50%;
}

.chat-item-left {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.user-msg-time {
  text-align: right;
  color: #5c5c5c;
  justify-content: flex-end;
  position: relative;
}

.user-msg-time p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  right: 15px;
  top: -25px;
}

.sender-msg-time {
  text-align: left;
  color: #5c5c5c;
  justify-content: flex-end;
  position: relative;
}

.sender-msg-time p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  left: 15px;
  top: -25px;
}

.chat-options {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 12px;
}

.text-field {
  width: 70%;
  height: 36px;
  background: #f4f4f4;
  display: flex;
  gap: 8px;
  padding: 0 8px;
  border-radius: 70px;
}

.text-field input {
  background-color: transparent;
  border: none;
}

.text-field img {
  height: 20px;
  width: 20px;
}

.action-buttons {
  display: flex;
  gap: 12px;
  cursor: pointer;
}

.action-buttons img {
  height: 36px;
  width: 36px;
}

.text-field,
.action-buttons {
  display: flex;
  align-items: center;
}

.cur-pointer {
  cursor: pointer;
}

.modal-body {
  width: 100%;
  overflow-y: auto;
  margin-bottom: 8px;
}

.convo-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* background: red; */
}

.chat-section::-webkit-scrollbar {
  display: none;
}

.conversation {
  width: 96%;
  border-radius: 4px;
  background-color: #497c5b;
  display: flex;
  justify-content: flex-start;
  padding: 8px 8px 4px 8px;
  gap: 8px;
  cursor: pointer;
  position: relative;
}

.conversation .profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.conversation .convo-info .name {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  line-height: 45px;
}

.conversation .unreadMessages {
  position: absolute;
  right: 15px;
  top: 13px;
  background: red;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
}

.chatButton {
  height: 50px;
  width: 50px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
}

.chatButton img {
  height: 30px;
  width: 30px;
  z-index: 999;
}

@media screen and (max-width: 500px) {

  .chatButton {
    width: 30px;
    height: 30px;
  }

  .chatButton img {
    width: 22px;
    height: 22px;
  }

}