.trip-filters {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 12px;
}

.trip-filters .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trip-filters .header h3 {
  color: #031a32;
  font-size: 14px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.trip-filters .header img {
  width: 13px;
  height: 13px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  cursor: pointer;
}

.label {
  color: #bfca67;
  font-size: 14px;
  /* font-family: Avenir Next; */
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 8px;
}

.filter .to {
  margin-bottom: 10px;
  color: #78909c;
  font-weight: 500;
}

.price-filter .filter {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-end;
}

.price-filter .filter .filter-item {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.price-filter .filter .filter-item .label {
  color: #78909c;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.trip-filters .filter-item .filter-input {
  display: flex;
}

.trip-filters .filter-item .filter-input .input-box {
  width: 50px;
  height: 40px;
  border-radius: 4px 0 0 4px;
  border: 0.5px solid var(--light-theme-card-border-color, #e3e9ef);
  background: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  border-right: none;
  color: #4d4d4d;
}

.trip-filters .filter-item .filter-input .input-box input {
  width: 80%;
  padding: 5px;
  display: flex;
  color: #031a32;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.trip-filters .filter-item .filter-input .currency-box {
  width: 50px;
  height: 40px;
  border-radius: 0 4px 4px 0;
  border: 0.5px solid var(--light-theme-card-border-color, #e3e9ef);

  background: green;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #78909c;
  font-size: 14px;
  font-weight: 500;
}

.trip-filters .filter-item .filter-input .currency-box input {
  width: 80%;
  padding: 5px;
  display: flex;
  color: #78909c;
  font-weight: 500;
}

/* ***************************************
  *********** SEARCH FILTER *************
  *************************************** */

.search {
  width: 100%;
  display: flex;
  height: 40px;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid rgba(92, 92, 92, 0.4);
  align-items: center;
  padding: 0 15px 0 15px;
}

.search input {
  width: 90%;
}

.search-icon {
  width: 16px;
  height: 16px;
}

.suggestions {
  margin-top: 7px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 300px;
  overflow-y: auto;
}

.suggestions .more {
  margin-top: 8px;
  color: var(--primary);
  font-size: 16px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 700;
  line-height: 130.8%;
  cursor: pointer;
}

.suggestion {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.suggestion .left img {
  width: 20px;
  aspect-ratio: 1;
  background: #fff;
  margin-right: 8px;
}

.suggestion .left {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.suggestion .left .check-label {
  text-transform: capitalize;
}

.suggestion .left .check-label-active {
  color: var(--primary);
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
}

.count-active {
  color: var(--primary);
}

.vertical-line {
  height: 18px;
  background: #e6e6e6;
  width: 1px;
}

.buttons {
  width: 100%;
  display: flex;
  gap: 10px;
}

.buttons button {
  display: flex;
  width: 50%;
  padding: 6px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--primary);
  color: #fff;
  font-weight: 700;
  border: 1px solid var(--primary);
}

.buttons .reset {
  background: #fff;
  color: var(--primary);
}

.date-filter-container {
  width: 100%;
}

.date-filter {
  margin-top: 8px;
  width: 100%;
  display: flex;
  height: 40px;
  padding: 12px 0px 12px 16px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid rgba(92, 92, 92, 0.4);
}

input[type="date"]::-webkit-datetime-value {
  color: navy;
  /* Change the text color to navy or your preferred color */
}

.date-filter input {
  width: 100% !important;
  -webkit-appearance: none;
  appearance: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 5px;
  cursor: text;
}
.date-filter {
  padding: 0px 10px 0px 15px;
}

.date-filter::after {
  content: attr(data-date);
  position: relative;
  z-index: 0;
  color: navy;
  /* Change the text color to your preferred color */
}
