@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Avenir Next" !important;
  font-style: normal !important;
}

code {
  font-family: "Avenir Next";
}

.cur-pointer {
  cursor: pointer;
}

/* toast styling */

.Toastify__toast-container {
  width: auto !important;
}

.Toastify__toast {
  min-height: auto !important;
  min-width: auto !important;
}

.Toastify__toast--success {
  background: #9897a9 !important;
  color: black;
  border-radius: 20px !important ;
  overflow: hidden;
}

.Toastify__toast-icon {
  display: none !important;
}

.Toastify__close-button > svg {
  display: none !important;
}

.Toastify__toast-body {
  text-align: center;
  padding: 0 1rem !important;
}

@keyframes custom-ease-in {
  0% {
    transform: translateY(-10);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.custom-ease-in {
  animation: custom-ease-in 1s ease-in both;
}

@keyframes custom-ease-out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-10);
    opacity: 0;
  }
}

.custom-ease-out {
  animation: custom-ease-out 1s ease-in both;
}
