.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.btn {
    background: var(--primary);
    color: white;
    width: 120px;
    height: 36px;
    border-radius: 8px;
    font-weight: 700;
}

.btn:hover {
    background: var(--primary);
    opacity: 0.9;
}

.btn:disabled {
    background: var(--primary);
    opacity: 0.9;
    cursor: not-allowed;
}

.container-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-item img {
    width: 60%;
    min-width: 250px;
}

.container-item h2 {
    font-size: 20px;
    font-weight: 600;
}

.container-item p {
    font-size: 12px;
    text-align: center;
}

.container-item .timer {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
}