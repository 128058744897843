/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Genos:wght@100;300;400;500;600;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Genos:wght@100;300;400;500;600;700&display=swap");

:root {
  --color-dark: #ebebeb;
  --primary: #1c4726;
  --secondry: #5c5c5c;
  --button: linear-gradient(270deg,
      #8a631c 0%,
      #c8a339 26.05%,
      #e9ca57 73.44%,
      #8a631c 100%);
  --gold: #dfb82d;
}

body {
  transition: all 200ms;
}

/* Remove up down arrow buttons in input[type="number"] */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.modal-open {
  overflow: hidden;
  pointer-events: none;
}

body {
  background: #ebebeb !important;
  overflow-x: hidden;
}

h3 {
  margin-bottom: 0px !important;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  border: none;
}

button {
  cursor: pointer;
}

main::after {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  border: none;
}

.search-bar {
  width: 20px;
  height: 20px;
}

/* gerneral style */
.container {
  width: 100% !important;
  margin: 0 auto;
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
  }
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.btn {
  display: inline-block;
}

/* ========= Nav Bar =====*/

nav {
  width: 100%;
  background: #ffffff;
  padding: 0.7rem 0px;
  position: fixed;
  top: 0;
  z-index: 99;
  height: 62px;
  border-bottom: 1px solid #ededed78;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

nav .nav-container {
  display: grid;
  grid-template-columns: 22vw auto 22vw;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

nav .search .searchButton img {
  width: 100%;
  aspect-ratio: 1;
  height: 16px;
  width: 16px;
}

nav .search {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  height: 40px;
  border: 1px solid rgba(92, 92, 92, 0.4);
  border-radius: 16px;
  align-items: center;
}

nav .searchTerm {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #5c5c5c;
  height: 20px;
}

nav .searchTerm::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #5c5c5c;
  height: 20px;
}

nav .searchButton {
  background: none;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  gap: 8px;
  margin-left: 12px;
  margin-right: 8px;
}

.messageNotifiButton {
  position: relative;
}

.messageNotifiButton button {
  background-color: rgb(0 161 209);
  color: white;
  font-weight: 600;
  width: 130px;
  height: 40px;
  padding: 15px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 21px;
  gap: 5px;
}

.messageNotifiButton .msgCount {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -12px;
  right: -12px;
  font-size: 12px;
  color: white;
  font-weight: 500;
  border-radius: 50%;
  border: 1px solid black;
}

.inputGroup {
  width: 120%;
  display: flex;
  border: 1px solid #edecec;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}

.inputGroup .value {
  font-size: 20px;
  font-weight: 500;
}

.inputGroup button {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: 600;
}

nav .inner-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: right;
  height: 40px;
  margin-right: 2rem;
}

.signup-button {
  margin-right: 15px;
  color: var(--primary);
  border: none;
  background-color: transparent;
  font-weight: 600;
}

.login-button {
  background-color: var(--primary);
  color: white;
  border: none;
  font-weight: 600;
}

/* nav .inner-wrap div {
    margin: 0px 15px;
} */

button#dropdown-basic {
  margin: 0px;
  padding: 0px;
  background: transparent;
  border: 0px;
  display: flex;
}

.site-logo {
  display: flex;
  justify-content: flex-start;
  width: 186px;
  height: 40px;
  margin-left: 12px;
}

.site-logo img {
  width: 80%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* ========= Nav Bar End =====*/

/* Main Section */
main {
  margin-top: 60px;
  position: relative;
  background-color: #fff;
}

main .container {
  display: grid;
  grid-template-columns: 22vw auto 22vw;
  column-gap: 2rem;
  position: relative;
  padding-top: 0;
}

/* Left Side Bar */
main .container .hide-left .left {
  display: none;
}

.main .container .show-left {
  display: none;
}

main .container .left {
  height: calc(100vh - 5.4rem);
  position: sticky;
  top: 4.4rem;
  left: 1rem;
  width: 80%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 235px;
  transition: left 0.5s ease;
}

.image-box {
  width: 95%;
}

.socialAuthorization {
  margin-top: 8px;
}

main .container .left::-webkit-scrollbar {
  display: none;
}

.profile-photo img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #5c5c5c;
}

.newTag {
  width: 30px;
}

.profile {
  display: flex;
  column-gap: 1rem;
  width: 100%;
}

.profile .text-muted {
  font-size: 11px;
  line-height: 15px;
  color: #5c5c5c;
  margin-top: 4px;
}

.profile .handle {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

/* === side bar menu =======*/
.left .sidebar {
  margin-bottom: 2px;
}

.left .sidebar .menu-item {
  display: flex;
  align-items: center;
  height: 2rem;
  cursor: pointer;
  translate: all 300ms ease;
  position: relative;
}

.left .sidebar h3 {
  font-weight: 500;
  font-size: 17px;
  line-height: 17px;
  color: #5c5c5c;
  margin-left: 10px;
  width: 100%;
  padding-top: 5px;
  text-transform: capitalize;
}

.left .sidebar img {
  width: 1.5rem;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.left .label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  color: #ffffff;
  height: 36px;
  margin-top: 1rem;
  background: #82ca9c;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
}

.left .sidebar .active h3 {
  font-weight: bold;
  color: var(--primary);
}

.left .sidebar .inactive h3 {
  font-weight: 500;
  padding-top: 5px;
}

.left .bottom-download-app-side-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.download-icons {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.download-icons img {
  width: 20px;
  height: 22px;
  margin-right: 5px;
}

.black-logo-name {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-items: center;
  align-items: center;
}

.black-logo-name img {
  width: 11px;
  height: 11px;
  margin-right: 10px;
}

.black-logo-name p {
  font-style: normal;
  
  font-size: 14px;
  line-height: 17px;
  color: #858585;
}

.terms-cookies {
  display: flex;
  width: 100%;
}

.terms-cookies p {
  margin-right: 7px;
  cursor: pointer;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #706d6d;
}

.left a {
  color: var(--bs-link-color);
  text-decoration: none;
}

/* Left Side Bar end*/

/* middle Side Bar start*/

.middle .trends-feeds {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  margin-top: 12px;
}

.middle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

*::-webkit-scrollbar {
  display: none;
}

.middle .trends-feeds .trend-feed {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 2px;
  padding-right: 5px;
  gap: 4px;
  isolation: isolate;
  background: #ffffff;
  /* box-shadow: 6px 6px 16px rgb(0 0 0 / 6%); */
  border-radius: 4px;
  margin-bottom: 1px;
  width: 100%;
}

.middle .trends-feeds .trend-feed:first-child {
  margin-top: 8px;
}

.middle .trends-feeds .trend-feed .head {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.middle .trends-feeds .trend-feed .head .trends-from {
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 21px;
  color: #000000;
  text-transform: uppercase;
}

.middle .head .user {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.middle .head .user h3 {
  color: #000000;
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

.middle .head .user small {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #5c5c5c;
}

.middle .head .user .profile-photo img {
  width: 32px;
  height: 32px;
  border-radius: 30px;
  margin-top: -7px;
  margin-right: -4px;
}

.middle .head img {
  width: 24px;
  height: 24px;
}

.middle .trends_tags {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;
  margin-top: 11px;
}

.middle .trends_tags p {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin-right: 8px;
  text-decoration-line: underline;
  margin-bottom: 8px;
  color: #82ca9c;
}

.middle .photo {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.feed-image video,
.feed-image img {
  width: 100%;
}

.feed-image {
  width: 100%;
}

/* CARROUSAL SLIDER  */

.carrousal {
  /* width: 40vw; */
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 250px;
}

.carrousal h3 {
  color: var(--black, #000);
  font-size: 18px;
  font-family: Avenir Next;
  font-weight: 700;
  line-height: 21px;
}

.carrousal-item .top {
  position: relative;
}

.carrousal-item .top .uniqueTag {
  width: 55px;
  position: absolute;
  top: 12px;
  left: 12px;
}

.viewMoreBox {
  position: absolute;
  width: 100%;
  height: 30px;
  left: 0;
  bottom: 0;
  background: #575757;
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.viewMore {
  cursor: pointer;
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9;
  width: 74px;
  height: 26px;
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  color: #fff;
}

.carrousal-item .bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.carrousal-item .bottom .artistName {
  color: var(--black, #000);
  font-size: 18px;
  font-family: Avenir Next;
  font-weight: 500;
  line-height: 27px;
  text-overflow: ellipsis;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
}

.carrousal-item .bottom .artName {
  color: var(--secondary, #5c5c5c);
  font-size: 16px;
  font-family: Avenir Next;
  line-height: 24px;
}

.carrousal-item .bottom .price {
  color: #000;
  font-size: 18px;
  font-family: Avenir Next;
  font-weight: 700;
}

.carrousal-item .bottom .new {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
}

.keys {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.keys .key span {
  color: var(--Secondary, #5c5c5c);
  font-family: Avenir Next;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 128.571% */
}

.values {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.values .value span {
  color: var(--Black, #000);
  font-family: Avenir Next;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
}

.values .value:last-child span:first-child {
  display: flex;
  align-items: center;
  gap: 4px;
}

.middle .photo .interaction-buttons {
  position: absolute;
  right: 23px;
  bottom: 15px;
  filter: drop-shadow(0 0 0.5rem #000);
}

.middle .photo .like {
  display: flex;
  align-items: center;
}

.middle .photo .like img {
  width: 27px;
  height: 24px;
}

.middle .photo .like .likecount {
  color: #ffffff;
  margin-right: 10px;
}

.middle .photo .share {
  display: flex;
  align-items: center;
}

.middle .photo .share img {
  width: 27px;
  height: 24px;
}

.middle .photo .share .sharecount {
  color: #ffffff;
  margin-right: 10px;
}

.middle .caption p {
  margin-top: 7px;
  width: 100%;
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #5c5c5c;
}

.read-more-less {
  cursor: pointer;
  color: #7cb94b;

  font-family: "Avenir Next";
  font-style: normal;
}

.leaders-image img {
  border-radius: 30px;
}

/* middle Side Bar end*/

/*======RIght side bar css==============*/

.right {
  height: max-content;
  position: sticky;
  top: 5.4rem;
}

.right .leaders-in-trends {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.right .leaders-in-trends h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.right .leaders-in-trends span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #68b082;
  margin-right: 15px;
}

.right .leaders-card {
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.right .leaders-card .leaders-info {
  width: 100%;
  display: flex;
}

.right .leaders-card .leaders-info .leaders-name h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.right .leaders-card .leaders-info .leaders-name small {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #5c5c5c;
}

.right .leaders-card .leaders-image {
  width: 38px;
  height: 38px;
  margin-right: 10px;
}

.right .leaders-card .leaders-from-site {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.right .ScrollStyle {
  max-height: 210px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  background-color: #f2f0f0;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  margin-left: 10px;
  background-color: #68a77e;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

label.latest-trend-button.btn:hover {
  color: #ffffff;
  height: 36px;
  margin-top: 1rem;
  background: #82ca9c;
  border-radius: 4px;
  cursor: pointer;
}

.bottom {
  display: flex;
  margin-top: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.locationIcon img,
.userProfilePic img {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  object-fit: cover;
}

.mentionBox {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(92, 92, 92, 0.4);
  border-radius: 12px;
  border-radius: 12px;
  margin-left: 8px;
  padding: 10px;
}

.mentionBox .buttons {
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.mentionBox .buttons .sendIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3.9804px 10.6144px;
  width: 37.15px;
  height: 23.88px;

  border: 1.3268px solid rgba(92, 92, 92, 0.4);
  border-radius: 10.6144px;
}

.mentionBox .buttons .sendIcon img {
  width: 18px;
  height: 18px;
}

.mentionBox .buttons .mentionIcon img {
  width: 22px;
  height: 22px;
}

.mentionBox .buttons .mentionIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2.54571px 7.63713px;
  gap: 2.55px;

  width: 46.52px;
  height: 22.91px;

  border: 1.27285px solid rgba(92, 92, 92, 0.4);
  border-radius: 10.1828px;
}

.mentionBox .buttons .mentionIcon span {
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  color: #000000;
}

.mentionBox input {
  border: none;
  width: 70%;
  height: 19px;
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: left;

  color: #5c5c5c;
}

.mentionBox input::placeholder {
  padding-top: 10px;
  height: 100px;
}

/*=======================================
MEDIA QUERIES FOR SMALL LAPTOPS AND BIG TABLETS
==========================================*/
@media screen and (max-width: 1200px) {
  .nav-container {
    max-width: 100%;
  }

  /* main .container {
    grid-template-columns: 13.6rem auto 13.6rem;
    gap: 1rem;
    width: 100%;
  } */

  main .container .left .profile {
    display: none;
  }

  .left .btn {
    display: none;
  }
}

.trendsInfiniteScroll {
  display: flex;
  overflow: hidden;
  width: 40vw;
  flex-direction: column;
  align-items: center;
}

/*=======================================
MEDIA QUERIES FOR SMALL TABLETS AND MOBILES 
==========================================*/
@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 100%;
  }
}

@media screen and (max-width: 992px) {

  /* vikas changes */
  main .container {
    display: block;
  }

  .carrousal {
    width: 80vw;
  }

  .site-logo {
    margin-left: 5px;
  }

  .site-logo img {
    width: 50%;
  }

  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }

  .trendsInfiniteScroll {
    width: 80vw;
  }

  /* .nav-container{
    grid-template-columns: 15vw auto 15vw;
  } */
  main .container {
    grid-template-columns: 5rem auto;
    gap: 0;
  }

  main .container .left {
    position: fixed;
    background: #fff;
    padding: 1px;
    padding-left: 2px;
    height: 130px;
    top: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 5px 15px 5px 15px;
    max-width: 350px;
    min-width: 255px;
    left: -500px;
    width: 50%;
    height: 100%;
    z-index: 100;
  }



  main .container .open {
    left: 0;
    padding-top: 25px;
  }

  .hamburger {
    display: flex !important;
  }

  main .container .middle {
    grid-column: 1/3;
  }

  main .container .right {
    display: none;
  }

  .bottom-download-app-side-bar {
    display: none;
  }

  .login-popup-box .image-box {
    display: none;
  }

  nav .container {
    display: grid;
    grid-template-columns: 0vw auto 0vw;
    column-gap: 0rem;
    position: relative;
  }

  .middle .trends_tags {
    display: flex;
    width: 100%;
    overflow: hidden;
  }
}

.user-name {
  display: flex;
  align-items: center;
  gap: 5px;
}

.user-name .name {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  color: var(--secondry);
}

.user-name .greet {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondry);
}

.profileImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@media screen and (max-width: 700px) {
  .site-logo img {
    width: 80%;
  }
}

@media screen and (max-width: 650px) {
  .profileImage {
    width: 35px;
    height: 35px;
    margin-top: 5px;
  }

  .trendsInfiniteScroll {
    min-width: 250px;
  }

  .search .searchTerm {
    height: 35px;
    width: 70%;
  }

  .search {
    align-items: center;
    justify-content: center;
  }
}

.sellArtwork-button {
  width: 100%;
  color: var(--gold);
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  border: 0.5px solid var(--gold);
  background: var(--button);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  padding: 4px 0 2px 0;
}

@media screen and (max-width: 650px) {
  .mentionBox .buttons .sendIcon {
    display: none;
  }

  .trendsInfiniteScroll {
    width: 95vw;
  }
}

@media screen and (max-width: 500px) {
  .artPage {
    padding-right: 2px;
  }

  .user-name {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .signup-button {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  nav .nav-container {
    grid-template-columns: 14vw auto 14vw;
    column-gap: 2px;
  }

  .login-button {
    width: 50px;
    height: 27px;
    font-size: 12px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.login-popup-rightbox {
  width: 100%;
  height: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 0;
  padding-bottom: 15px;
}

.login-popup-rightbox .submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: 100%;
  height: 36px;
  background: var(--primary);
  border-radius: 4px;
  border-color: transparent;
  color: white;
  font-weight: 500;
  align-self: center;
  transition: 0.5s;
  font-weight: 700;
}

.modal .modal-lg {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-button {
  background-color: var(--primary);
  color: #fff;
  border: none;
  font-weight: 600;
}

.login-button:hover {
  background-color: #347042;
  color: #fff;
}

.signup-button:hover {
  background-color: var(--primary);
  color: #fff;
}

.login-popup-rightbox .submit:hover {
  background-color: #05240c;
}

.login-popup-rightbox .nav-link {
  color: #5c5c5c;
}

.login-popup-rightbox .nav-link .active {
  color: #000000;
}

.login-popup-rightbox .forget-password-button {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: var(--primary);
  background: transparent;
  float: right;
  margin-top: 10px;
  text-decoration: none;
}

.login-popup-rightbox .forget-password-button :hover {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #04aa6d;
  background: transparent;
  float: right;
  margin-top: 10px;
}

.login-popup-box {
  display: flex;
  gap: 2rem;
}

.login-popup-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeBtn {
  width: 17px;
  cursor: pointer;
}

.group3 {
  cursor: pointer;
  width: 170px;
}

.following-hashtags {
  display: flex;
  gap: 8px;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 12px;
}

.following-hashtags-top {
  width: 100%;
  height: fit-content;
}

.following-hashtags-top p {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  margin-bottom: 0;

  color: #858585;
}

.following-hashtags-bottom {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  height: fit-content;
}

.following-hashtags-bottom span {
  width: fit-content;
  height: fit-content;
  margin-right: 8px;
  margin-bottom: 5px;
  padding: 3px 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  cursor: pointer;
  /* identical to box height */
  border-radius: 4px;
  background: rgba(215, 225, 136, 0.3);
  color: var(--primary);
}

.terms-cookies {
  flex-wrap: wrap;
  gap: 0;
  max-width: 200px;
}

.terms-cookies p {
  color: #858585;
  height: fit-content;
  width: fit-content;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
}

.socialAuthorizationIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 12px;
}

.socialAuthorizationIcons img {
  width: 32px;
  height: 32px;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.08));
  cursor: pointer;
}

.login-box-title {
  margin-top: 10px;
}

.login-box-title h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 150%;
  color: #1c4726;
}

.login-box-title p {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: #9f9f9f;
}

/* Bootstrap modified class  */
.nav-tabs {
  --bs-nav-tabs-border-width: 3px;
  --bs-nav-tabs-border-color: #f1f5f8;
  --bs-nav-tabs-link-hover-border-color: #fff #fff #67a77f;
  --bs-nav-tabs-link-active-color: #000000;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #fff #fff #80ca9c;
  --bs-nav-tabs-link-active-border-width: 5px;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

/* Bootstrap modified class  */
.horizontalLine {
  /* margin: 12px 0; */
  margin-bottom: 14px;
  width: 100%;
  height: 1px;
  background-color: #ededed78;
}

.horizontalLineWithMargin {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background-color: #ededed78;
}

.partnerLeftBox {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-left: 15px;
}

.partnerLeftBox .header {
  height: 20px;
}

.content {
  height: 50px;
}

.partnerLeftBox .header p {
  font-size: 19px;
  font-weight: 600;
  color: gray;
}

.partnerLeftBox .content .amt {
  font-size: 42px;
  font-weight: 600;
  color: black;
}

.detailsButton {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(76, 197, 197);
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {

  /* if screen size goes less than 1200 do not show the right bar  */
  .right {
    display: none;
  }
}

.scrollToTop {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  position: fixed;
  right: 28px;
  bottom: 100px;
  z-index: 100;
  background-color: #fff;
  cursor: pointer;
}

.hamburger {
  display: none;
  align-items: center;
  /* height: 1.5rem; */
  position: fixed;
  width: 1.5rem;
  left: 6px;
  color: var(--primary);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 40px;
  aspect-ratio: 1;
  padding: 4px;
  top: 11px;
  z-index: 101;
}

/* left bar p--contartner profile styling
 */

.left-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding-top: 10px;
}

.left-container .upper {
  width: 100%;
  margin-bottom: 12px;
  /* margin-top: 40px; */
}

.left-container .upper span {
  color: var(--secondary, #5c5c5c);
  font-size: 12px;
  font-weight: 400;
}

.left-container .upper .name {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.left-container .upper .name h3 {
  color: var(--black, #000);
  font-size: 20px;
  font-weight: 700;
}

.left-container .upper .name span {
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.left-container .mid {
  width: 80%;
  display: flex;
  align-items: start;
  gap: 10px;
}

.left-container .mid .item {
  width: 100%;
  display: flex;
  align-items: start;
  gap: 12px;
}

.left-container .mid .item .key span {
  color: var(--secondary, #5c5c5c);
  font-size: 14px;
  font-weight: 400;
}

.left-container .mid .item .value span {
  color: var(--black, #000);
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.left-container .mid .item .value span:nth-child(2) {
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 500;
}

.left-container .mid .item:last-child .value {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.verify-icon {
  height: 18px;
  width: 18px;
}

.left-container .bottom {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: flex-start;
  gap: 8px;
}

.create-place,
.left-container .bottom button {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--primary, #1c4726);
  background: #fff;
  color: var(--primary, #1c4726);
  font-size: 14px;
  font-weight: 700;
  padding: 2px 0 0 0;
}

.left-container .bottom .create-trip {
  border-radius: 4px;
  border: 1px solid var(--primary, #1c4726);
  background: var(--primary, #1c4726);
  color: #fff;
}

.create-place {
  width: 100%;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  padding: 2px 0 0 0;
  background: var(--primary, #1c4726);
  color: #fff;
  /* margin-bottom: 20px; */
}

.partner-footer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px 0;
}

.partner-footer span,
.partner-footer div span {
  color: var(--secondary, #5c5c5c);
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.partner-footer span:nth-child(2) {
  color: var(--primary, #1c4726);
  text-decoration-line: underline;
}

.partner-footer span:last-child {
  color: #e15241;
  text-decoration-line: underline;
}

.admin-left-info {
  width: 100%;
}

.admin-left-info .title span {
  color: var(--Secondary, #5c5c5c);
  font-size: 12px;
  font-weight: 400;
}

.admin-left-info .items .p-item {
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.admin-left-info .items {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: flex-start;
}

.admin-left-info .items .p-item .item-left span {
  color: var(--Secondary, #5c5c5c);
  font-size: 14px;
  font-weight: 400;
}

.admin-left-info .items .p-item .item-right {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.admin-left-info .items .p-item .item-right span {
  color: var(--Black, #000);
  font-size: 14px;
  font-weight: 500;
}

.admin-left-info .items .p-item .item-right span:nth-child(2) {
  font-size: 9px;
}

.card-icon p {
  font-size: 12px;
  width: 15px;
  height: 13px;
  display: flex;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #ff4d42;
  position: absolute;
  padding: 10px;
  top: -10px;
  right: -18px;
  font-family: monospace;
  color: #fff;
}

.parent-dropdown {
  position: relative;
}

.add {
  color: #a97d62;
  font-family: Avenir Next;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  border-radius: 4px;
  border: 1px solid #a97d62;
}

.parent-dropdown p {
  font-size: 12px;
  width: 15px;
  height: 13px;
  display: flex;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #ff4d42;
  position: absolute;
  padding: 10px;
  top: -5px;
  right: -7px;
  right: 0;
  font-family: monospace;
  color: #fff;
}

.empty {
  width: 44px;
}

.reply-box {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
}

.reply-box.open {
  max-height: 100px;
  /* Adjust the max height as needed */
}

.commentLike {
  /* Your button styles go here */
  transition: transform 0.2s ease-in-out;
}

.commentLike.pop {
  animation: pop 0.5s ease-in-out;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}