.container {
  background: rgba(255, 255, 255, 0.13);
  height: 400;
  padding: 25px;
}

.trip-details-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 30px;
}

.mandatory {
  color: red;
}

h3 {
  color: var(--Black, #000);
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 700;
}

.item-100 .label,
.item-half-width .label,
.header span span:last-child,
.item .label {
  color: var(--light-theme-medium-speciality-icon-gray, #63778b);
  font-family: Avenir Next;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

h3 {
  color: var(--Black, #000);
  font-size: 16px;
  font-weight: 700;
}

.item {
  width: 47%;
  margin-top: 15px;
}

.create {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create button {
  border-radius: 4px;
  background: #a97d62;
  width: 130px;
  color: #fff;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding: 4px 0 0 0;
}

.ticket-price {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d0dae4;
  gap: 5px;
}

.ticket-price span:first-child {
  color: #b5b5b5;
  font-size: 16px;
  font-weight: 500;
}

.item .title,
.item-100 .title {
  border-bottom: 1px solid #d0dae4;
  background: none;
  width: 100%;
}

.item .title::placeholder,
.item-100 .title::placeholder {
  color: var(--light-theme-main-typography, #031a32);
  font-size: 16px;
  font-weight: 400;
}

.custom-select {
  width: 100%;
  height: 25px;
  border-bottom: 1px solid #d0dae4;
  background: none;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.check-box-container {
  width: 100%;
  display: flex;
  gap: 24px;
  margin-top: 15px;
}

.check-box-container .check-box {
  display: flex;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}

.check-box-container .check-box img {
  width: 20px;
  height: 20px;
}

.text-active,
.text-green,
.text-inactive {
  color: var(--gold);
  font-size: 14px;
  font-weight: 600;
}

.text-green {
  color: var(--primary);
}

.text-inactive {
  color: var(--light-theme-medium-speciality-icon-gray, #63778b);
  font-weight: 500;
}
