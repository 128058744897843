.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 999;
}

.loaderBox img {
  height: 8rem;
  width: 8rem;
}

.load-cont {
  display: flex;
}

.load-orange {
  position: relative;
  width: 3rem;
  aspect-ratio: 1/1;
  background-color: #407742;
  border-radius: 100%;
  animation: 0.5s ease-in-out -1s alternate-reverse infinite orange-anim;
}

.load-green {
  position: relative;
  width: 3rem;
  aspect-ratio: 1/1;
  background-color: #e09132;
  border-radius: 100%;
  transition: all;
  animation: 0.5s ease-in-out -1s alternate-reverse infinite green-anim;
}

@keyframes orange-anim {
  0% {
    transform: translateX(0%);
    /* z-index: 10; */
  }
  90% {
    /* z-index: 20; */
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes green-anim {
  0% {
    transform: translateX(0%);
    /* z-index: 20; */
  }
  90% {
    /* z-index: 10; */
  }
  100% {
    transform: translateX(-100%);
    z-index: 10;
  }
}
