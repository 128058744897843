.notifiBox {
  width: 400px;
  display: flex;
  min-height: 90px;
  flex-direction: column;
  height: 0px;
  opacity: 100;
  position: absolute;
  top: 60px;
  right: 135px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  align-items: center;
  overflow-y: auto;
}

@media screen and (max-width: 1200px) {
  .notifiBox {
    right: 1rem;
    width: 90vw;
  }
}

.notification {
  display: flex;
  width: 380px;
  justify-content: space-between;
  padding: 5px;
  align-items: center;
}
.notifcations {
  height: 300px;
}

.notificationLeft {
  height: 60px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  gap: 10px;
}

.leftSection {
  display: flex;
  align-items: center;
}
.leftSection img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.middlesSection {
  width: 150px;
  display: flex;
  align-items: center;
}
.middleUpper {
  display: flex;
  gap: 7px;
  align-items: center;
}
.middleLower {
  font-size: 13px;
  font-weight: 300;
  color: gray;
}
.rightSection {
  display: flex;
  align-items: flex-end;
}
.rightSection img {
  height: 45px;
  width: 45px;
  border-radius: 8px;
}
