.form-container {
  width: 40vw;
  margin-top: 10px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 250px;
  margin-bottom: 20px;
  margin-top: 30px;
  gap: 30px;
}

.mandatory {
  color: #ff4e4e;
  font-weight: 500;
  margin: 0 3px;
  line-height: 12px;
  padding-top: 5px;
}

.trip-details {
  width: 100%;
}

.trip-details-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.trip-details-container .date {
  border-bottom: 1px solid #d0dae4;
}

.title-and-destination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trip-details .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-100 .label,
.item-half-width .label,
.header span span:last-child,
.item .label {
  color: var(--light-theme-medium-speciality-icon-gray, #63778b);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

h3 {
  color: var(--Black, #000);
  font-size: 16px;
  font-weight: 700;
}

.item {
  width: 47%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.item .title,
.item-100 .title {
  border-bottom: 1px solid #d0dae4;
  background: none;
  width: 100%;
}

.item .title::placeholder,
.item-100 .title::placeholder {
  font-size: 16px;
  font-weight: 400;
}

.custom-select {
  width: 100%;
  height: 25px;
  border-bottom: 1px solid #d0dae4;
  background: none;
}

input[type="number"] {
  width: 100%;
}

/* CUSTOM DATE INPUT  */

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.date {
  position: relative;
}

.date input {
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("../assets/icons/date-picker-icon.svg") no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

::-webkit-datetime-edit {
  text-transform: uppercase;
  color: #b5b5b5;
  font-size: 16px;
  font-weight: 400;
}

/* CUSTOM DATE INPUT  */

/* FILE DRAG AND DROP  */
.item-100 {
  width: 100%;
  margin-top: 20px;
}

.dropzone {
  text-align: center;
  border: 2px dashed #d0dae4;
  cursor: pointer;
  margin: 12px 0;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  border-radius: 8px;
  width: 64%;
}

.dropzone img {
  width: 40px;
  height: 40px;
}

.drop-text {
  color: var(--light-theme-type-medium-grey, #78909c);
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.selected-file-wrapper {
  text-align: center;
}

.selected-file {
  color: var(--primary);
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

/* FILE DRAG AND DROP  */

.ticket-price {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d0dae4;
  gap: 5px;
}

.ticket-price span:first-child {
  color: #b5b5b5;
  font-size: 16px;
  font-weight: 500;
}

.ticket-contents-continer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.ticket-contents-item {
  width: 90%;
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 5px;
  cursor: pointer;
}

.ticket-contents-item span {
  color: var(--light-theme-medium-speciality-icon-gray, #63778b);
  font-size: 13px;
  font-weight: 500;
}

.ticket-contents-item img {
  width: 23px;
}

.personalised-pricing {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.personalised-pricing-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.item-full-width .title {
  width: 90%;
  border-bottom: 1px solid #d0dae4;
}

.item-half-width {
  width: 45%;
}

.check-box-container {
  width: 100%;
  display: flex;
  gap: 24px;
  margin-top: 4px;
}

.check-box-container .check-box {
  display: flex;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}

.check-box-container .check-box img {
  width: 20px;
  height: 20px;
}

.text-active,
.text-inactive {
  color: var(--gold);
  font-size: 14px;
  font-weight: 600;
}

.text-inactive {
  color: var(--light-theme-medium-speciality-icon-gray, #63778b);
  font-weight: 500;
}

.create {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.create button {
  border-radius: 4px;
  background: var(--button);
  width: 115px;
  color: #fff;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding: 6px 0 2px 0;
}

.create button:first-child {
  color: var(--gold);
  background: #fff;
  border: 0.5px solid var(--gold);
}

@media screen and (max-width: 850px) {
  .trip-details-container {
    flex-direction: column;
  }

  .ticket-contents-item,
  .item {
    width: 90%;
  }

  .ticket-contents-continer {
    flex-direction: column;
  }

  .dropzone {
    width: 90%;
  }

  .input {
    font-size: 14px;
  }
}

/* Select Partner Box css  */

.dropdown-mentionbox {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  justify-content: center;
  box-shadow: 0px 22px 34px 0px rgba(0, 0, 0, 0.2);
  max-height: 100px;
  overflow-x: auto;
}

.dropdown:empty {
  border: none;
}

.dropdown-row {
  cursor: pointer;
  text-align: start;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
  display: flex;
  gap: 12px;
  align-items: center;
  border-bottom: 0.5px solid #d0dae4;
}

.dropdown-row:last-child {
  border-bottom: none;
}

.dropdown-row:hover {
  background-color: aliceblue;
}

.dropdown-row img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.dropdown-row div {
  display: flex;
  flex-direction: column;
}

.dropdown-row div span:first-child {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  line-height: 16px;
}

.dropdown-row div span:last-child {
  font-size: 14px;
  color: #746f6f;
  font-weight: 400;
  line-height: 16px;
}

.tagBox {
  /* position: relative; */
  z-index: 99;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mentionBox {
  width: 90%;
}

.page-head {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.dark-line {
  width: 100%;
  height: 1px;
  background-color: #adadad;
  margin-top: 12px;
}

.page-head h3 {
  color: var(--gold);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
